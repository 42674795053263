<template>
  <v-container grid-list-md class="pa-6">
    <v-row justify="space-around">
      <v-col md="12">
        <h1 class="text-h4 mb-3 font-weight-light">
          {{ $t('termsAndConditions.document.title') }}
        </h1>
        <h4 class="mb-3">{{ $t('termsAndConditions.lastUpdateDate') }}</h4>
        <div class="mb-3">
          <h3>{{ $t('termsAndConditions.document.termsSection.title') }}</h3>
          <p>{{ $t('termsAndConditions.document.termsSection.first') }}</p>
          <p>{{ $t('termsAndConditions.document.termsSection.second') }}</p>
          <p>{{ $t('termsAndConditions.document.termsSection.third') }}</p>
          <p>{{ $t('termsAndConditions.document.termsSection.fourth') }}</p>
        </div>
        <div class="mb-3">
          <h3>{{ $t('termsAndConditions.document.licenseSection.title') }}</h3>
          <p>
            {{ $t('termsAndConditions.document.licenseSection.first.text') }}
          </p>
          <ul class="mb-3">
            <li>
              {{
                $t('termsAndConditions.document.licenseSection.first.firstItem')
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.licenseSection.first.secondItem'
                )
              }}
            </li>
            <li>
              {{
                $t('termsAndConditions.document.licenseSection.first.thirdItem')
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.licenseSection.first.fourthItem'
                )
              }}
            </li>
            <li>
              {{
                $t('termsAndConditions.document.licenseSection.first.fifthItem')
              }}
            </li>
          </ul>
          <p>{{ $t('termsAndConditions.document.licenseSection.second') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.disclaimerSection.title') }}
          </h3>
          <p>{{ $t('termsAndConditions.document.disclaimerSection.first') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.limitationSection.title') }}
          </h3>
          <p>{{ $t('termsAndConditions.document.limitationSection.first') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.revisionsSection.title') }}
          </h3>
          <p>{{ $t('termsAndConditions.document.revisionsSection.first') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.linksSection.title') }}
          </h3>
          <p>{{ $t('termsAndConditions.document.linksSection.first') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.siteTermsSection.title') }}
          </h3>
          <p>{{ $t('termsAndConditions.document.siteTermsSection.first') }}</p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.yourPrivacySection.title') }}
          </h3>
          <p>
            {{ $t('termsAndConditions.document.yourPrivacySection.first') }}
          </p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.eligibilitySection.title') }}
          </h3>
          <p
            v-html="$t('termsAndConditions.document.eligibilitySection.first')"
          ></p>
        </div>
        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.ownershipSection.title') }}
          </h3>
          <p>
            {{ $t('termsAndConditions.document.ownershipSection.first') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.prohibitionsSection.title') }}
          </h3>
          <p>
            {{
              $t('termsAndConditions.document.prohibitionsSection.first.text')
            }}
          </p>
          <ul class="mb-3">
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.firstItem.title'
                )
              }}
              <ul>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.firstItem'
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.secondItem'
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.thirdItem'
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.fourthItem'
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.fifthItem'
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      'termsAndConditions.document.prohibitionsSection.first.firstItem.sixthItem'
                    )
                  }}
                </li>
              </ul>
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.secondItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.thirdItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.fourthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.fifthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.sixthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.seventhItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.eighthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.ninthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.tenthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.eleventhItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.twelfthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.thirteenthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.fourteenthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.fifteenthItem'
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'termsAndConditions.document.prohibitionsSection.first.sixteenthItem'
                )
              }}
            </li>
          </ul>
          <p>
            {{ $t('termsAndConditions.document.prohibitionsSection.second') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.interactionsSection.title') }}
          </h3>
          <p>
            {{ $t('termsAndConditions.document.interactionsSection.first') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.liabilitySection.title') }}
          </h3>
          <p>
            {{ $t('termsAndConditions.document.liabilitySection.first') }}
          </p>
          <p>
            {{ $t('termsAndConditions.document.liabilitySection.second') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.governingSection.title') }}
          </h3>
          <p>
            {{ $t('termsAndConditions.document.governingSection.first') }}
          </p>
        </div>

        <div class="mb-3">
          <h3>
            {{ $t('termsAndConditions.document.contactingUsSection.title') }}
          </h3>
          <p
            v-html="$t('termsAndConditions.document.contactingUsSection.first')"
          ></p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'terms-and-conditions'
};
</script>
